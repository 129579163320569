<template>
  <div>
    <div class="columns is-justify-content-center">
      <div class="column is-two-fifths-tablet">
        <div class="has-text-centered">
          <div class="profile-img mb-2">
            <b-tooltip :label="$t('user.change_photo')" position="is-bottom" type="is-success">
              <img
                  @click="changePhoto"
                  :src="form.photo"
                  alt="Sgara Logo">
            </b-tooltip>
          </div>
          <input type="file" accept="image/*" class="is-hidden" ref="photo" @change="handleChangePhoto"/>
          <h3 class="is-size-4 has-text-weight-bold">{{ form.email }}</h3>
        </div>

        <sg-tab v-model="selectedAction"
                :items="['Profile', 'Password']"
                extended
                class="px-4 border-top-0 mt-3 mb-5"/>

        <ValidationObserver ref="profile" v-show="selectedAction === 'Profile'">
          <form @submit.prevent="handleSubmit" class="mt-4">
            <ValidationProvider :name="$t('user.username')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('user.username')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="text" id="username" v-model="form.username" placeholder=""></b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider :name="$t('user.phone')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('user.phone')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <vue-phone-number-input
                    color="#FF6600"
                    valid-color="#45BE7D"
                    error-color="#FF4060"
                    default-country-code="ID"
                    v-model="phone" @update="handleInputPhoneNumber" :placeholder="$t('user.phone')"/>
              </b-field>
            </ValidationProvider>
            <ValidationProvider :name="$t('city')" v-slot="{ errors }">
              <b-field :label="$t('city')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="text" id="city" v-model="form.city" placeholder=""></b-input>
              </b-field>
            </ValidationProvider>

            <b-button type="is-primary is-gradient" expanded class="mb-3" native-type="submit">{{ $t('save') }} Profile</b-button>
          </form>
        </ValidationObserver>

        <ValidationObserver ref="password" v-show="selectedAction === 'Password'">
          <form @submit.prevent="handleSubmitPassword" class="mt-4">
            <ValidationProvider :name="$t('user.old_password')" rules="required" v-slot="{ errors }" vid="password">
              <b-field :label="$t('user.old_password')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="password" autocomplete="dis" v-model="form_password.old_password"
                         placeholder="Enter your Old Password"></b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider :name="$t('user.old_password')" rules="required" v-slot="{ errors }" vid="new_password">
              <b-field :label="$t('user.old_password')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="password" autocomplete="dis" id="new_password" v-model="form_password.new_password"
                         placeholder="Enter your New Password"></b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider :name="$t('user.password_conf')" rules="required|confirmed:new_password" v-slot="{ errors }">
              <b-field :label="$t('user.password_conf')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="password" autocomplete="dis" id="new_confirm_password" v-model="form_password.confirm_password"
                         placeholder="Repeat your Password"></b-input>
              </b-field>
            </ValidationProvider>

            <b-button type="is-primary is-gradient" expanded class="mb-3" native-type="submit">{{ $t('save') }} Password</b-button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SgTab from "@/components/Sg/SgTab";

export default {
  name: "Profile",
  components: {
    SgTab,
  },
  data() {
    return {
      photo: null,
      form: {},
      form_password: {},
      selectedAction: 'Profile',
      phone: null,
    }
  },
  computed: {
    ...mapGetters('user', [
      'user',
    ]),
  },
  created() {
    this.form = this._.cloneDeep(this.user)
    this.phone = this._.cloneDeep(this.form.phone)
    if (!this.form.photo) this.form.photo = '/img/assets/profile.jpg'
  },
  methods: {
    ...mapActions('user', [
      'updateProfile',
      'updatePassword',
    ]),

    handleInputPhoneNumber(payload) {
      this.form.phone = payload.e164;
    },

    changePhoto() {
      this.$refs.photo.click()
    },

    handleChangePhoto(e) {
      this.$base64(e.target.files[0]).then((res) => {
        this.form.photo = res
      })
    },

    handleSubmit() {
      let {username, phone, city, photo} = this.form

      let {email, first_name} = this.user

      this.$refs.profile.validate().then(success => {
        if (success) {
          this.$loading()
          this.updateProfile({
            username,
            phone,
            email,
            first_name,
            city,
            photo,
          }).finally(() => {
            this.$loading(false)
          })
        }
      })
    },
    handleSubmitPassword() {
      this.$refs.password.validate().then(success => {
        if (success) {
          this.$loading()
          this.updatePassword(this.form_password).then(res => {
            if (!res.error) {
              this.$buefy.dialog.alert({
                message: res.data.message,
                confirmText: 'OK'
              })
              this.form_password = {}
              this.$refs.password.reset()
            }
          }).finally(() => {
            this.$loading(false)
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.profile-img {
  img {
    width: 92px;
    height: 92px;
    border-radius: 500px;
    object-fit: cover;
    position: relative;
    cursor: pointer;
  }
}
</style>
