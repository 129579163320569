var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-justify-content-center"},[_c('div',{staticClass:"column is-two-fifths-tablet"},[_c('div',{staticClass:"has-text-centered"},[_c('div',{staticClass:"profile-img mb-2"},[_c('b-tooltip',{attrs:{"label":_vm.$t('user.change_photo'),"position":"is-bottom","type":"is-success"}},[_c('img',{attrs:{"src":_vm.form.photo,"alt":"Sgara Logo"},on:{"click":_vm.changePhoto}})])],1),_c('input',{ref:"photo",staticClass:"is-hidden",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.handleChangePhoto}}),_c('h3',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v(_vm._s(_vm.form.email))])]),_c('sg-tab',{staticClass:"px-4 border-top-0 mt-3 mb-5",attrs:{"items":['Profile', 'Password'],"extended":""},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}}),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedAction === 'Profile'),expression:"selectedAction === 'Profile'"}],ref:"profile"},[_c('form',{staticClass:"mt-4",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('user.username'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('user.username'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text","id":"username","placeholder":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('user.phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('user.phone'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('vue-phone-number-input',{attrs:{"color":"#FF6600","valid-color":"#45BE7D","error-color":"#FF4060","default-country-code":"ID","placeholder":_vm.$t('user.phone')},on:{"update":_vm.handleInputPhoneNumber},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('city'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text","id":"city","placeholder":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})],1)]}}])}),_c('b-button',{staticClass:"mb-3",attrs:{"type":"is-primary is-gradient","expanded":"","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('save'))+" Profile")])],1)]),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedAction === 'Password'),expression:"selectedAction === 'Password'"}],ref:"password"},[_c('form',{staticClass:"mt-4",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitPassword.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('user.old_password'),"rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('user.old_password'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"password","autocomplete":"dis","placeholder":"Enter your Old Password"},model:{value:(_vm.form_password.old_password),callback:function ($$v) {_vm.$set(_vm.form_password, "old_password", $$v)},expression:"form_password.old_password"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('user.old_password'),"rules":"required","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('user.old_password'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"password","autocomplete":"dis","id":"new_password","placeholder":"Enter your New Password"},model:{value:(_vm.form_password.new_password),callback:function ($$v) {_vm.$set(_vm.form_password, "new_password", $$v)},expression:"form_password.new_password"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('user.password_conf'),"rules":"required|confirmed:new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('user.password_conf'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"password","autocomplete":"dis","id":"new_confirm_password","placeholder":"Repeat your Password"},model:{value:(_vm.form_password.confirm_password),callback:function ($$v) {_vm.$set(_vm.form_password, "confirm_password", $$v)},expression:"form_password.confirm_password"}})],1)]}}])}),_c('b-button',{staticClass:"mb-3",attrs:{"type":"is-primary is-gradient","expanded":"","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('save'))+" Password")])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }